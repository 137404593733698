/* istanbul ignore file */
import { stringify } from 'query-string'
import {
  FDRStatsQueryItem,
  OrganizationContentModel,
  OrganizationItem,
  PaginationRequest,
  PaginationResponse,
} from '../types'

import { Entry } from 'contentful'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  CONFIG: '/auth/config',
  ORGANIZATIONS: '/org/stats',
}

export type OrganizationSortField =
  | 'orgName'
  | 'userCount'
  | 'registeredUserCount'
  | 'fieldCount'
  | 'committedFieldCount'

export interface OrganizationQuery extends Omit<PaginationRequest<{}>, 'sort'> {
  sortField?: string
  sortDirection?: 'asc' | 'desc'
  // fdr sorting will override the top level sorting above
  fdrStats?: FDRStatsQueryItem[]
}

export interface ConfigResponse {
  content: Entry<OrganizationContentModel>
}

export class OrganizationAPI {
  static list(query?: OrganizationQuery) {
    const { sortDirection, sortField, ...params } = query || {}
    const sortParam =
      sortField && sortDirection ? { sortField: `${sortField}:${sortDirection}` } : undefined

    return agent
      .get<PaginationResponse<OrganizationItem>>(
        `${PATHS.ORGANIZATIONS}?${stringify({
          ...sortParam,
          ...params,
          fdrStats: !!query?.fdrStats ? JSON.stringify(query?.fdrStats) : undefined,
        })}`
      )
      .then(response => response?.data)
  }

  static config() {
    return agent.get<ConfigResponse>(PATHS.CONFIG).then(response => response?.data)
  }
}
