import { Skeleton, useTheme } from '@mui/material'
import { useOrganizationContent } from '../../queries/useOrganizationContent'

interface BrandLogoProps {
  altText?: string
  contentfulOrgId?: string
  logoStyle?: any
  size?: 'small' | 'large'
  variant?: 'square' | 'default'
}

const LOGO_FIELD = {
  default: {
    dark: 'logoDarkLarge',
    light: 'logoLightLarge',
  },
  square: {
    dark: 'logoDarkSquare',
    light: 'logoLightSquare',
  },
} as const

export const BrandLogo = ({
  altText,
  contentfulOrgId,
  logoStyle,
  size,
  variant = 'default',
}: BrandLogoProps) => {
  const theme = useTheme()

  const width =
    !size && !!logoStyle?.width && typeof logoStyle.width === 'number'
      ? logoStyle.width
      : size === 'small'
      ? 60
      : 100
  const height =
    !!logoStyle?.height && typeof logoStyle.height === 'number' ? logoStyle.height : undefined

  // @todo: get other orgs' content via backend proxy like we do current user's org content
  const { isPending, isError, isFetching, data } = useOrganizationContent({ contentfulOrgId })

  if (isPending || isFetching || isError) {
    return <Skeleton height={32} width={width} />
  }

  const logoField = LOGO_FIELD[variant][theme.palette.mode]

  const url = data?.fields[logoField].fields.file.url
  const selectedAltText = altText || data?.fields[logoField].fields.description

  const sourceSet = !!height
    ? `${url}?h=${height}, ${url}?h=${height * 2} 2x, ${url}?h=${height * 3} 3x`
    : `${url}?w=${width}, ${url}?w=${width * 2} 2x, ${url}?w=${width * 3} 3x`

  return (
    <img
      alt={selectedAltText}
      src={url}
      srcSet={sourceSet}
      style={{ width, objectFit: 'contain', ...logoStyle }}
    />
  )
}
