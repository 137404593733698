import { GlobalAuthState } from '@cibo/core'
import { Box, Stack, Typography } from '@mui/material'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { ProgramEngagementProvider, useProgramEngagementContext } from '../../context'
import { useProgramEngagement } from '../../queries'
// @ts-ignore tsconfig needs help for svg imports
import { flatten, path, prop, uniq } from 'ramda'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import imgUrl from './CiboLogo.svg'
import { SelectedPracticesTable } from './SelectedPracticesTable'
import { usePracticeRecommendationContent } from './usePracticeRecommendationContent'

export const EqipSelectedPracticesPDF = () => {
  const { engagementId } = useParams()

  const [params] = useSearchParams({ authorization: '' })
  const authToken = params.get('authToken')

  const [authReady, setAuthReady] = useState(false)

  useEffect(() => {
    if (!authToken) return
    GlobalAuthState.setAuthStateValid(authToken)
    setAuthReady(true)
  }, [authToken])

  const engagement = useProgramEngagement(
    { id: engagementId },
    { enabled: !!engagementId && !!authReady }
  )

  if (!engagementId) return null

  return (
    <ProgramEngagementProvider
      basePath={''}
      engagementId={engagementId}
      userId={Number.NaN}
      programId={engagement.data?.programId ?? ''}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
  @page {
    size: A4 portrait;
    margin: 0.5in;
  }
`,
        }}
      />

      <Header />

      <SelectedPracticesTable
        engagementId={engagementId}
        detailRequirements={[{ dataType: 'field', traitId: 'eqipEligibilityFarmInfo' }]}
        resourceIds={engagement.data?.fields ?? []}
      />
    </ProgramEngagementProvider>
  )
}

const Header = () => {
  const { t } = useTranslation('@cibo/programs/EqipSelectedPracticesPDF')
  const { engagement, ownerName } = useProgramEngagementContext()
  const fieldModels = useEqipEngagementFieldsWithConcerns(engagement.data?.id)

  const selectedPracticeIds =
    fieldModels.data && uniq(flatten(fieldModels.data.map(prop('selectedPractices'))))

  const practiceRecommendationContent = usePracticeRecommendationContent()

  const selectedPracticeItems = selectedPracticeIds?.map(practiceId =>
    practiceRecommendationContent.data?.items.find(
      practice => practice.fields.practiceId === practiceId
    )
  )

  const selectedPractices =
    selectedPracticeItems &&
    selectedPracticeItems
      .slice(0, -1)
      .map(path(['fields', 'name']))
      .join(', ') +
      ' & ' +
      selectedPracticeItems.slice(-1)[0]?.fields.name

  return (
    <Stack style={{ pageBreakAfter: 'always' }} spacing={3}>
      <Box style={{ width: '150px' }}>
        <Image src={imgUrl} style={{ display: 'block', width: '150px' }} />
      </Box>

      <Typography variant="h6">{t('title', { farmerName: ownerName })}</Typography>

      <Typography>{t('intro', { farmerName: ownerName, selectedPractices })}</Typography>

      <Typography>{t('cta', { farmerName: ownerName })}</Typography>
    </Stack>
  )
}
