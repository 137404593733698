import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Stack, TableCell, Typography } from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { AmendmentEventDisplayProps } from './AmendmentEventTypes'

export const AmendmentEventDisplay = ({
  name,
  onPressRemove,
  setExpanded,
  isImmutable,
}: AmendmentEventDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentEditor')
  const [field] = useField(name)
  return (
    <>
      <TableCell>{t('eventDate', { date: new Date(field.value.date) })}</TableCell>
      <TableCell>
        <Stack>
          <Typography variant="caption">{t(`type_${field.value.amendmentType}`)}</Typography>
          <Typography variant="caption">{t(`method_${field.value.method}`)}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {t('eventRate', { rate: field.value.rate })} {t(`units_${field.value.units}`)}
      </TableCell>
      <TableCell>
        <IconButton
          aria-label={t('editEvent')}
          disabled={isImmutable}
          onClick={() => setExpanded(true)}
        >
          <EditIcon />
        </IconButton>
        <IconButton aria-label={t('removeEvent')} disabled={isImmutable} onClick={onPressRemove}>
          <ClearIcon />
        </IconButton>
      </TableCell>
    </>
  )
}
