import { GlobalAuthState } from '@cibo/core'
import { CiboUiContext, ROUTE } from '@cibo/ui'
import { useQueryClient } from '@tanstack/react-query'
import qs from 'query-string'
import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks'
import {
  authCheck,
  authDirectLogout,
  authSessionInvalid,
  refreshUserInfo,
  selectAuthSessionValid,
  selectAuthToken,
} from '../../store'

export const AuthCheck = () => {
  const { isLoggedIn } = useAuth()
  const { events } = useContext(CiboUiContext)
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const jwt = useSelector(selectAuthToken)
  const wasValid = useSelector(selectAuthSessionValid)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      events.dispatchEvent(new CustomEvent('close_modals'))
      queryClient.clear()
    }
    const mostRecentLocation = GlobalAuthState.mostRecentLocation()
    if (isLoggedIn && !!mostRecentLocation) {
      mostRecentLocation.pathname !== ROUTE.ACCOUNT_LOGOUT && navigate(mostRecentLocation)
      GlobalAuthState.setMostRecentLocation(undefined)
    }
  }, [isLoggedIn])

  if (wasValid && !GlobalAuthState.isAuthorized()) {
    dispatch(authDirectLogout({ location }))
  }

  useEffect(() => {
    // look for auth0 token in url param
    const { id_token } = window.location
      ? (qs.parse(window.location.hash) as { id_token: string })
      : ({} as { id_token: undefined })

    if (id_token && typeof id_token === 'string') {
      dispatch(authCheck({ jwt: id_token, location }))
    } else if (jwt) {
      dispatch(authCheck({ jwt, location })) // look for stored auth token in store
    } else {
      dispatch(authSessionInvalid()) // no auth token found - resolve invalid session
      dispatch(refreshUserInfo())
    }
  }, [])

  return null
}
