import { TilesetService } from './TilesetService'
import geoutils from './helper/geo'
import Image from './image'
import MultiPolygon from './multipolygon'
import { buildSvgOverlay } from './svg2png'

export class StaticMaps {
  constructor(options = {}) {
    this.options = options

    this.tileLayers = options.tileLayers

    this.width = this.options.width
    this.height = this.options.height
    this.paddingX = this.options.paddingX || 0
    this.paddingY = this.options.paddingY || 0
    this.padding = [this.paddingX, this.paddingY]
    this.tileSize = this.options.tileSize || 256
    this.tileRequestTimeout = this.options.tileRequestTimeout
    this.tileRequestHeader = this.options.tileRequestHeader
    this.tileRequestLimit = Number.isFinite(this.options.tileRequestLimit)
      ? Number(this.options.tileRequestLimit)
      : 2
    this.reverseY = this.options.reverseY || false
    const zoomRange = this.options.zoomRange || {}
    this.zoomRange = {
      min: zoomRange.min || 1,
      max: this.options.maxZoom || zoomRange.max || 17, // maxZoom
    }
    // this.maxZoom = this.options.maxZoom; DEPRECATED: use zoomRange.max instead

    // # features
    this.markers = []
    this.lines = []
    this.multipolygons = []
    this.masks = []
    this.circles = []
    this.text = []
    this.bounds = []

    // # fields that get set when map is rendered
    this.center = []
    this.centerX = 0
    this.centerY = 0
    this.zoom = 0
  }

  addMultiPolygon(options) {
    this.multipolygons.push(new MultiPolygon(options))
  }

  addMask(geometry) {
    const options = {
      fill: '#ffffffff', // draw the mask interior
      color: '#00000000', // no outline
    }

    switch (geometry.type) {
      case 'FeatureCollection':
        geometry.features.forEach(feature => {
          this.addMask(feature.geometry)
        })
        break

      case 'Polygon':
        this.masks.push(
          new MultiPolygon({
            coords: geometry.coordinates,
            ...options,
          })
        )
        break

      case 'MultiPolygon':
        geometry.coordinates.forEach(coords => {
          this.masks.push(
            new MultiPolygon({
              coords,
              ...options,
            })
          )
        })
        break

      default:
        console.log('can not mask with geometry', geometry)
    }
  }

  /**
   * Render static map with all map features that were added to map before
   */
  async render(centerOrBbox, zoom) {
    if (!this.lines && !this.markers && !this.multipolygons && !(centerOrBbox && zoom)) {
      throw new Error('Cannot render empty map: Add  center || lines || markers || polygons.')
    }

    this.center = centerOrBbox
    this.zoom = zoom || this.getExtentZoom(22)

    const maxZoom = this.zoomRange.max
    if (maxZoom && this.zoom > maxZoom) this.zoom = maxZoom

    if (centerOrBbox && centerOrBbox.length === 2) {
      this.centerX = geoutils.lonToX(centerOrBbox[0], this.zoom)
      this.centerY = geoutils.latToY(centerOrBbox[1], this.zoom)
    } else {
      // # get extent of all lines
      const extent = this.determineExtent(this.zoom)

      // # calculate center point of map
      const centerLon = (extent[0] + extent[2]) / 2
      const centerLat = (extent[1] + extent[3]) / 2

      this.centerX = geoutils.lonToX(centerLon, this.zoom)
      this.centerY = geoutils.latToY(centerLat, this.zoom)
    }

    this.image = new Image(this.options)

    if (!this.tileLayers?.length) {
      this.image.draw([])
    } else {
      for (const layer of this.tileLayers) {
        await this.drawTileLayer(layer, this.zoom)
      }
    }

    await this.drawFeatures()
    await this.applyMasks()

    return this.image.image
  }

  /**
   * calculate common extent of all current map features
   */
  determineExtent(zoom) {
    const extents = []

    // Add bbox to extent
    if (this.center && this.center.length >= 4) extents.push(this.center)

    // add bounds to extent
    if (this.bounds.length) {
      this.bounds.forEach(bound => extents.push(bound.extent()))
    }

    // Add polylines and polygons to extent
    // if (this.lines.length) {
    //   this.lines.forEach(line => {
    //     extents.push(line.extent())
    //   })
    // }

    if (this.multipolygons.length) {
      this.multipolygons.forEach(multipolygon => {
        extents.push(multipolygon.extent())
      })
    }

    // Add circles to extent
    // if (this.circles.length) {
    //   this.circles.forEach(circle => {
    //     extents.push(circle.extent())
    //   })
    // }

    // Add marker to extent
    // for (let i = 0; i < this.markers.length; i++) {
    //   const marker = this.markers[i]
    //   const e = [marker.coord[0], marker.coord[1]]
    //
    //   if (!zoom) {
    //     extents.push([marker.coord[0], marker.coord[1], marker.coord[0], marker.coord[1]])
    //     continue
    //   }
    //
    //   // # consider dimension of marker
    //   const ePx = marker.extentPx()
    //   const x = geoutils.lonToX(e[0], zoom)
    //   const y = geoutils.latToY(e[1], zoom)
    //
    //   extents.push([
    //     geoutils.xToLon(x - parseFloat(ePx[0]) / this.tileSize, zoom),
    //     geoutils.yToLat(y + parseFloat(ePx[1]) / this.tileSize, zoom),
    //     geoutils.xToLon(x + parseFloat(ePx[2]) / this.tileSize, zoom),
    //     geoutils.yToLat(y - parseFloat(ePx[3]) / this.tileSize, zoom),
    //   ])
    // }

    return [
      Math.min(...extents.map(e => e[0])),
      Math.min(...extents.map(e => e[1])),
      Math.max(...extents.map(e => e[2])),
      Math.max(...extents.map(e => e[3])),
    ]
  }

  /**
   * calculate the best zoom level for given extent
   */
  getExtentZoom(forceMaxZoom) {
    for (let z = forceMaxZoom || this.zoomRange.max; z >= this.zoomRange.min; z--) {
      const extent = this.determineExtent(z)
      const width = (geoutils.lonToX(extent[2], z) - geoutils.lonToX(extent[0], z)) * this.tileSize
      if (width > this.width - this.padding[0] * 2) continue

      const height = (geoutils.latToY(extent[1], z) - geoutils.latToY(extent[3], z)) * this.tileSize
      if (height > this.height - this.padding[1] * 2) continue

      return z
    }
    return this.zoomRange.min
  }

  /**
   * transform tile number to pixel on image canvas
   */
  xToPx(x) {
    const px = (x - this.centerX) * this.tileSize + this.width / 2
    return Number(Math.round(px))
  }

  /**
   * transform tile number to pixel on image canvas
   */
  yToPx(y) {
    const px = (y - this.centerY) * this.tileSize + this.height / 2
    return Number(Math.round(px))
  }

  async drawTileLayer(config, zoom) {
    if (!config || !config.tileUrl) {
      // Early return if we shouldn't draw a base layer
      return this.image.draw([])
    }
    const xMin = Math.floor(this.centerX - (0.5 * this.width) / this.tileSize)
    const yMin = Math.floor(this.centerY - (0.5 * this.height) / this.tileSize)
    const xMax = Math.ceil(this.centerX + (0.5 * this.width) / this.tileSize)
    const yMax = Math.ceil(this.centerY + (0.5 * this.height) / this.tileSize)

    const tileConfigs = []

    for (let x = xMin; x < xMax; x++) {
      for (let y = yMin; y < yMax; y++) {
        // # x and y may have crossed the date line
        const maxTile = 2 ** zoom
        const tileX = (x + maxTile) % maxTile
        let tileY = (y + maxTile) % maxTile
        if (this.reverseY) tileY = (1 << zoom) - tileY - 1

        tileConfigs.push({
          address: { x: tileX, y: tileY, z: zoom },
          // url: tileUrl,
          box: [this.xToPx(x), this.yToPx(y), this.xToPx(x + 1), this.yToPx(y + 1)],
        })
      }
    }

    const tiles = await TilesetService.getTiles(config, tileConfigs)

    return this.image.draw(tiles.filter(v => v.success).map(v => v.tile))
  }

  /**
   *  Draw all features to the basemap
   */
  async drawFeatures() {
    // await this.drawFeature(this.lines, (c) => this.lineToSVG(c));
    await this.drawFeature(this.multipolygons, mp => mp.toSVG(this))
    // await this.drawMarkers();
    // await this.drawFeature(this.text, (c) => this.textToSVG(c));
    // await this.drawFeature(this.circles, (c) => this.circleToSVG(c));
  }

  async drawFeature(features, svgFunction) {
    if (!features.length) return

    for (const feature of features) {
      const overlay = await buildSvgOverlay(svgFunction(feature), this.image)

      if (overlay) {
        this.image.image.composite(overlay, 0, 0)
      }
    }
  }

  async applyMasks() {
    if (!this.masks.length) return

    const maskImage = await createJimp(this.image.width, this.image.height, 0x00000000)

    for (const feature of this.masks) {
      const overlay = await buildSvgOverlay(feature.toSVG(this), this)

      if (overlay) {
        maskImage.composite(overlay, 0, 0)
      }
    }

    this.image.image.mask(maskImage, 0, 0)
  }
}

const createJimp = (w, h, bg) =>
  new Promise((resolve, reject) => {
    new Jimp(w, h, bg, (err, image) => {
      if (err) return reject(err)

      resolve(image)
    })
  })
