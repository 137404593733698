/* eslint-disable */
import { Canvg, presets } from 'canvg'
import { DOMParser } from 'xmldom'

const preset = presets.offscreen({
  DOMParser,
})

export const buildSvgOverlay = async (svgElements, { width, height }) => {
  if (!OffscreenCanvas || typeof OffscreenCanvas === 'undefined') {
    return
  }
  const svg = `<svg
        width="${width}px"
        height="${height}px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        ${svgElements}
      </svg>`

  const canvas = new OffscreenCanvas(width, height)
  const ctx = canvas.getContext('2d', { willReadFrequently: true })
  const v = await Canvg.from(ctx, svg, preset)

  // Render only first frame, ignoring animations and mouse.
  await v.render()

  const blob = await canvas.convertToBlob()

  return Jimp.read(await blob.arrayBuffer())
}
