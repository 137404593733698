import { getLocale, initTranslationFormat } from '@cibo/core'
import { useSelfOrgContent } from '@cibo/profile'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18n from 'i18next'
import { useEffect } from 'react'
import { initReactI18next } from 'react-i18next'

import resources from './en-US.json'
const locale = getLocale()

dayjs.extend(dayOfYear)
dayjs.extend(calendar)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

i18n.use(initReactI18next) // passes i18n down to react-i18next

initTranslationFormat(i18n, resources, { DEV_ENV: Number(import.meta.env.VITE_DEV_ENV) })

export const useOrgDialect = () => {
  const contentQuery = useSelfOrgContent()

  useEffect(() => {
    const dialect = contentQuery.data?.fields.dialect?.fields.language
    const resources = contentQuery.data?.fields.dialect?.fields.resources

    if (dialect && resources) {
      for (const [namespace, strings] of Object.entries(resources)) {
        i18n.addResourceBundle(dialect, namespace, strings, true, true)
      }

      i18n.changeLanguage(dialect)
    } else {
      i18n.changeLanguage(locale)
    }
  }, [contentQuery.data])
}

export default i18n
