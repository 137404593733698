import { LIVESTOCK_SPECIES, RDGrazingEvent } from '@cibo/core'
import { DateRangePickerField, NumberInputField, SelectField } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, styled } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { EventComponentProps } from '../EventDetail'

//dayjs extensions should be moved somewhere else
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter)

const CompactCell = styled(TableCell)(() => ({
  paddingLeft: 4,
  paddingRight: 4,
  verticalAlign: 'top',
  '& .MuiFormControl-root': {
    width: '100%',
  },
}))

export const GrazingEventEditor = ({
  year,
  name,
  onPressRemove,
  parentName,
  eventIndex,
  isImmutable,
}: EventComponentProps) => {
  const { t } = useTranslation('@cibo/landmanager/GrazingEditor')
  const defaultMonth = new Date(year, 3)
  const { getFieldMeta } = useFormikContext()
  const parentField = getFieldMeta<{ events: RDGrazingEvent[] }>(parentName)

  return (
    <>
      <TableRow key={name}>
        <CompactCell>
          <DateRangePickerField
            startRangeFieldName={`${name}.startDate`}
            endRangeFieldName={`${name}.endDate`}
            shouldDisableDate={(day: Date, position) =>
              parentField.value.events
                .filter((f, i) => i !== eventIndex)
                .some(
                  event =>
                    event.startDate &&
                    event.endDate &&
                    dayjs(day).isSameOrAfter(dayjs(event.startDate)) &&
                    (dayjs(day).isSame(dayjs(event.endDate)) ||
                      dayjs(day).isBefore(dayjs(event.endDate)))
                )
            }
            name={name}
            disabled={isImmutable}
            minDate={new Date(year - 1, 6, 1)}
            maxDate={new Date(year + 1, 0, 1)}
            defaultCalendarMonth={defaultMonth}
          />
        </CompactCell>

        <CompactCell>
          <SelectField
            disabled={isImmutable}
            fieldName={`${name}.species`}
            options={LIVESTOCK_SPECIES}
            renderOption={value => t('species', { context: value })}
            fullWidth
          />
        </CompactCell>

        <CompactCell>
          <NumberInputField
            name={`${name}.stockingRate`}
            min={0}
            max={2000}
            disabled={isImmutable}
            step={0.1}
            unit={t('stockingRateUnit')}
            data-testid={`${name}.stockingRate`}
          />
        </CompactCell>

        <CompactCell>
          <IconButton aria-label={t('removeEvent')} onClick={onPressRemove} disabled={isImmutable}>
            <ClearIcon />
          </IconButton>
        </CompactCell>
      </TableRow>
    </>
  )
}
