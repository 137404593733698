import { LIMING_TYPE, LIMING_UNIT, LimingEvent } from '@cibo/core'
import { DatePickerField, SelectField } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, TableCell as MuiTableCell, TableRow, styled } from '@mui/material'
import { Field as FormikField } from 'formik'
import { TextField as FMTextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { EventComponentProps } from '../EventDetail'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'

const TableCell = styled(MuiTableCell)(() => ({
  '& .MuiFormControl-root': {
    width: '100%',
  },
}))

export const LimingEventEditor = ({
  year,
  name,
  onPressRemove,
  requirement,
  filterInputs,
  isImmutable,
}: EventComponentProps) => {
  const { t } = useTranslation('@cibo/landmanager/LimingEditor')

  const defaultMonth = new Date(year, 9)

  const isRequired = useIsRequired(requirement)
  const show = useShowDetailAttribute<LimingEvent>(filterInputs)

  return (
    <TableRow key={name}>
      <TableCell sx={{ verticalAlign: 'top', padding: '6px' }}>
        <DatePickerField
          InputProps={{ required: isRequired('date') }}
          name={`${name}.date`}
          disabled={isImmutable}
          minDate={new Date(year - 1, 6, 1)}
          maxDate={new Date(year + 1, 0, 1)}
          defaultCalendarMonth={defaultMonth}
          data-testid={`${name}.date`}
        />
      </TableCell>

      {show('type') && (
        <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px', padding: '6px' }}>
          <SelectField
            sx={{ width: '100%' }}
            required={isRequired('type')}
            fieldName={`${name}.type`}
            disabled={isImmutable}
            options={LIMING_TYPE}
            renderOption={t}
          />
        </TableCell>
      )}

      <TableCell sx={{ verticalAlign: 'top', padding: '6px' }}>
        <FormikField
          required={isRequired('amount')}
          disabled={isImmutable}
          component={FMTextField}
          name={`${name}.amount`}
          InputProps={{
            type: 'number',
            inputProps: {
              min: 0,
            },
          }}
          data-testid={`${name}.amount`}
        />
      </TableCell>

      <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px', padding: '6px' }}>
        <SelectField
          sx={{ width: '100%' }}
          disabled={isImmutable}
          required={isRequired('unit')}
          fieldName={`${name}.unit`}
          options={LIMING_UNIT}
          renderOption={t}
        />
      </TableCell>

      <TableCell>
        <IconButton aria-label={t('removeEvent')} onClick={onPressRemove} disabled={isImmutable}>
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
