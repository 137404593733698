import { OrganizationAPI, OrganizationContentModel } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { Entry } from 'contentful'
import { useAuth } from '../hooks/useAuth'
import { QUERY_KEY } from './queryKey'

export const useSelfOrgContent = () => {
  const { userId } = useAuth()

  return useQuery<Entry<OrganizationContentModel>, unknown, Entry<OrganizationContentModel>>({
    queryKey: [QUERY_KEY.ORGANIZATION_CONTENT, userId],
    queryFn: () =>
      OrganizationAPI.config().then(
        response => (response?.content as Entry<OrganizationContentModel>) || null
      ),
    staleTime: Infinity,
  })
}
