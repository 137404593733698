import { MpxResponseBase } from '../types/MpxTypes'
import { AgreementStatus } from './AgreementTypes'
import { Persona, UserId } from './AuthTypes'
import { CollectionSpace } from './CollectionTypes'
import { FDRStatus, FDRStatusQuery } from './FieldsTypes'
import { PaginationRequest } from './PaginationTypes'
import {
  BenchmarkStatus,
  Field,
  FieldEligibilityState,
  FieldMetadata,
  ProgramBenchmarkName,
  ProgramReward,
  ResourceProgramState,
  RewardOpportunity,
  RewardToken,
} from './Program'
import { ResourceDetail } from './ResourceDetails'

export const FIELD_QUERY_MAX_LIMIT = 1000

export interface FieldMetadataUpdateRequest {
  metadata: Partial<FieldMetadata>
  resourceIds: string[]
  action?: 'merge' | 'replace' | 'remove' | 'clear'
}

export interface FieldResponse extends MpxResponseBase {
  item: Field
}
export interface FieldsResponse extends MpxResponseBase {
  items: Field[]
}

export type FieldQuerySortFields =
  | 'acres'
  | 'county'
  | 'createdAt'
  | 'updatedAt'
  | 'name'
  | 'programCount'
  | 'programInteractedAt'
  | 'recommendationScore'
  | 'state'

export type ProgramFieldSelector = {
  id: string
  benchmarks?: Array<{
    benchmark: ProgramBenchmarkName
    status: BenchmarkStatus
  }>
  participating?: boolean
  workflow?: boolean
}

export const FDR_FILTER_STATUSES = [
  'overlapsField',
  'overlapsSelf',
  'needsInfo',
  'ineligibleForEnrollment',
  'eligibleForEnrollment',
  'enrolling',
  'enrolled',
  'completed',
  'contracted',
]

export type FieldBenchmarkStatus = typeof FDR_FILTER_STATUSES[number]

export type OfferSearchParameters = {
  gtePaymentAmount?: number
  ltPaymentAmount?: number
  gteExpirationDate?: string //timestamp
  ltExpirationDate?: string //timestamp
  status?: AgreementStatus
  contracted?: boolean
  offerGroup?: string
}

export interface FieldQueryFilters {
  fips?: string[]
  cashCrop?: string[]
  coverCrop?: string[]
  tillage?: string[]
  acresLt?: number
  acresGt?: number
  labels?: string[]
  lastUpdatedGt?: string
  lastUpdatedLt?: string
  createdGt?: string
  createdLt?: string
  termsets?: string[]
  grower?: string
  owner?: string | UserId // Email address or userId of the owner of the field.
  userId?: UserId
  orgId?: string
}

export type FieldQueryFilterName = keyof FieldQueryFilters

export interface FieldQueryOptions
  extends PaginationRequest<FieldQuerySortFields>,
    FieldQueryFilters {
  resourceIds?: string
  includeAllDetails?: boolean
  includeAllResolved?: boolean
  fullText?: string
  footprintResolved?: boolean
  involvedWithProgram?: boolean
  profiled?: boolean
  space?: CollectionSpace
  programs?: Array<ProgramFieldSelector>
  anyPrograms?: Array<ProgramFieldSelector>
  allPrograms?: Array<ProgramFieldSelector>
  persona?: Persona
  programStateIds?: string[]
  programState?: Array<ResourceProgramState | '!selected' | '!committed' | '!completed' | 'none'>
  programStatusIds?: string[]
  programStatus?: Array<FieldEligibilityState | '!eligible' | '!ineligible' | '!incomplete'>
  programPhaseIds?: string[]
  programPhase?: Array<ProgramBenchmarkName | '!prequalify' | '!enrollment'>
  name?: string

  availableForWorkflow?: {
    programId: string
  }

  workflow?: string // this is a workflow id

  prgGlobalSearchStatus?: Array<FieldBenchmarkStatus>
  prgGlobalSearchStatusProgramId?: string // scope the filter above
  fdrStatusAnyOf?: FDRStatusQuery[]
  fdrStatusAllOf?: FDRStatusQuery[]

  agreementId?: string
  searchOption?: 'all' | 'syndicating' | 'nonSyndicating'

  offerSearch?: OfferSearchParameters
}

/**
 * ugh
 * Why this doesn't extend RewardOpportunity is beyond me.  This
 * response resembles a RewardOpportunity but is... different
 */
export interface FieldFDRStats extends Pick<RewardOpportunity, 'acres'> {
  programId: string
  status: FDRStatus
  fields?: RewardOpportunity['numResources'] // yep!!
  // I can't even with this...
  rewards?: {
    opportunities: Record<RewardToken, Pick<ProgramReward, 'amount' | 'token'>>
  }
  personaCounts?: Record<Persona, { fields: number }>
}

export interface FieldFDRStatsResponse extends MpxResponseBase {
  fdrStats: FieldFDRStats[]
}

export interface FieldsStatsQueryOptions extends FieldQueryOptions {
  fdrStats?: Array<FDRStatusQuery>
}

export interface FieldGeoJSONQueryOptions extends Omit<FieldQueryOptions, 'resourceIds'> {
  lowerLeft: [number, number]
  upperRight: [number, number]
  workflow?: string
  owner?: string | UserId // Email address or userId of the owner of the field.
}

export interface LocalLedgerFieldsRequest {
  lowerLeft: [number, number]
  upperRight: [number, number]
}

export interface FieldShapefilesQueryOptions
  extends Omit<FieldQueryOptions, 'offset' | 'limit' | 'sort'> {
  //Choice of mapping of field data to metadata for the DBF (DBase 3 format) file.
  dbfMappingChoice?: 'defaultOrgMapping' | 'standard'
  //The base name of the 'save as' file name returned in the response. If it is not provided, a name will be generated.
  fileName?: string
}

export interface AssignFieldsRequestParams {
  userId: UserId
  resourceIds: string[]
  messageUser: boolean
  customMessage?: string
}

export type CreateFieldRequest = {
  details: ResourceDetail[]
  owner?: UserId
  metadata: any
  space?: CollectionSpace
}

type ResourceModification = {
  resourceId: string
  name?: string
  labels?: string[]
}

export type ConfirmImportsRequest = {
  resourceIds: string[]
  owner?: UserId
  messageUser?: boolean
  customMessage?: string
  resourceModifications?: ResourceModification[]
}
