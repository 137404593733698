import { Markdown } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { Children, PropsWithChildren, cloneElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelfOrgContent } from '../../queries'

export const ContactSupportButton = ({
  buttonText,
  children,
  onClose,
  ...rest
}: PropsWithChildren<ButtonProps & { buttonText?: string; onClose?: () => void }>) => {
  const { t } = useTranslation('contactSupportButton')
  const [openModal, setOpenModal] = useState(false)
  const { isPending, isError, isFetching, data } = useSelfOrgContent()

  return (
    <>
      {!!children ? (
        Children.map(children, child =>
          // @ts-ignore child, which is created by this react function is apparently not typed correctly
          cloneElement(child, {
            onClick: (e: any) => setOpenModal(true),
            disabled: isError,
          })
        )
      ) : (
        <LoadingButton
          loading={isPending || isFetching}
          disabled={isError}
          variant="contained"
          onClick={e => setOpenModal(true)}
          {...rest}
        >
          {!!buttonText ? buttonText : t('buttonValue')}
        </LoadingButton>
      )}
      <Dialog
        open={openModal}
        onClose={() => {
          onClose && onClose()
          setOpenModal(false)
        }}
      >
        <DialogContent>
          <Stack spacing={2} sx={{ p: 4 }}>
            <Typography variant="h4">{t('title')}</Typography>
            <Divider />
            <Markdown typographyProps={{ variant: 'body1' }}>
              {data?.fields.supportInformation.fields.helpDialogBody}
            </Markdown>
            <Stack direction="row" justifyContent="end">
              <Button onClick={() => setOpenModal(false)}>{t('close')}</Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
