import {
  AMENDMENT_METHOD,
  AMENDMENT_TYPE,
  AMENDMENT_UNITS,
  RDAmendment,
  AmendmentEvent as RDAmendmentEvent,
} from '@cibo/core'
import { DatePickerField, SelectField } from '@cibo/ui'
import { Button, FormControl, FormLabel, InputAdornment, Stack, TableCell } from '@mui/material'
import { Field, useField } from 'formik'
import { TextField as FMTextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { useShowDetailAttribute } from '../../useShowDetailAttribute'
import { AmendmentEventEditorProps } from './AmendmentEventTypes'

export const AmendmentEventEditor = ({
  name,
  onPressRemove,
  setExpanded,
  shouldDisableDate,
  year,
  filterInputs,
  isImmutable,
}: AmendmentEventEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentEditor')

  const formikField = useField<RDAmendmentEvent>(name)
  const field = formikField[1]
  const { setValue } = formikField[2]

  const show = useShowDetailAttribute<RDAmendment['input']['events'][0]>(filterInputs)

  const defaultMonth = new Date(year - 1, 10)

  const onResetValue = () => {
    if (!!field.initialValue) {
      setExpanded(false)
      setValue(field.initialValue)
    } else {
      onPressRemove()
    }
  }

  return (
    <>
      <TableCell colSpan={4}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <FormControl>
              <FormLabel>{t('date')}</FormLabel>
              <DatePickerField
                name={`${name}.date`}
                minDate={new Date(year - 1, 6, 1)}
                maxDate={new Date(year + 1, 0, 1)}
                defaultCalendarMonth={defaultMonth}
                shouldDisableDate={shouldDisableDate}
                disabled={isImmutable}
                inputFormat="M/d/yyyy"
                InputProps={{
                  //@ts-ignore this will be passed to the input element, but is not in the type definition
                  'data-testid': `${name}.date`,
                }}
              />
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t('type')}</FormLabel>

              <SelectField
                fieldName={`${name}.amendmentType`}
                options={AMENDMENT_TYPE}
                renderOption={option => t(`type_${option}`)}
                disabled={isImmutable}
              />
            </FormControl>
          </Stack>

          <FormControl>
            <FormLabel>{t('method')}</FormLabel>

            <SelectField
              fieldName={`${name}.method`}
              options={AMENDMENT_METHOD}
              renderOption={option => t(`method_${option}`)}
              disabled={isImmutable}
            />
          </FormControl>

          {show('nitrogenPercentage') && (
            <FormControl>
              <FormLabel>{t('nitrogen')}</FormLabel>
              <Field
                component={FMTextField}
                disabled={isImmutable}
                name={`${name}.nitrogenPercentage`}
                InputProps={{
                  type: 'number',
                  inputProps: {
                    min: 0,
                    max: 100,
                  },
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                data-testid={`${name}.nitrogenPercentage`}
              />
            </FormControl>
          )}

          <Stack direction="row" spacing={1}>
            <FormControl>
              <FormLabel>{t('rate')}</FormLabel>
              <Field
                component={FMTextField}
                name={`${name}.rate`}
                disabled={isImmutable}
                InputProps={{
                  type: 'number',
                  inputProps: {
                    min: 0,
                  },
                  sx: { width: 100 },
                }}
                data-testid={`${name}.rate`}
              />
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>{t('units')}</FormLabel>

              <SelectField
                fieldName={`${name}.units`}
                disabled={isImmutable}
                options={AMENDMENT_UNITS}
                renderOption={option => t(`units_${option}`)}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button color="error" variant="outlined" onClick={onResetValue}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={() => setExpanded(false)}
              disabled={
                !!field.error ||
                isImmutable ||
                JSON.stringify(field.initialValue) === JSON.stringify(field.value)
              }
            >
              {!!field.initialValue ? t('update') : t('add')}
            </Button>
          </Stack>
        </Stack>
      </TableCell>
    </>
  )
}
