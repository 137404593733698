import { AmendmentEvent as RDAmendmentEvent } from '@cibo/core'
import { TableRow } from '@mui/material'
import { useField } from 'formik'
import { useState } from 'react'
import { EventComponentProps } from '../../EventDetail'
import { AmendmentEventDisplay } from './AmendmentEventDisplay'
import { AmendmentEventEditor } from './AmendmentEventEditor'

export const AmendmentEvent = ({ year, name, onPressRemove, isImmutable }: EventComponentProps) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, { initialValue }, {}] = useField<RDAmendmentEvent | undefined>(name)
  const [expanded, setExpanded] = useState(!initialValue)

  return (
    <TableRow key={name}>
      {expanded ? (
        <AmendmentEventEditor
          name={name}
          onPressRemove={onPressRemove}
          setExpanded={setExpanded}
          year={year}
          isImmutable={isImmutable}
        />
      ) : (
        <AmendmentEventDisplay
          name={name}
          setExpanded={setExpanded}
          onPressRemove={onPressRemove}
          isImmutable={isImmutable}
        />
      )}
    </TableRow>
  )
}
