import { FieldQueryOptions, FieldQuerySortFields, SortModel } from '@cibo/core'
import { DataGridPro, EmptyState } from '@cibo/ui'
import { Box } from '@mui/material'
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridSortModel } from '@mui/x-data-grid-pro'
import { uniq } from 'ramda'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePaginatedFields } from '../../queries'
import { PaginatedFieldsContext } from './PaginatedFieldsContext'
import { PAGE_SIZES } from './PaginatedFieldsProvider'
import { PaginatedFieldsTableProps } from './PrivatePaginatedFieldsTableTypes'
import { DEFAULT_PAGINATED_FIELDS_COLUMNS } from './columnConfigurations'

export const PaginatedFieldsTable = ({
  autoHeight = true,
  baseQuery = {},
  columns,
  columnVisibilityModel,
  slots = {},
  slotProps,
  dataLoading = false,
  initialSort,
  onRowsChange,
  pinnedColumns = {},
  mutateSortModel,
  ...dataGridProProps
}: PaginatedFieldsTableProps) => {
  const { t } = useTranslation('@cibo/landmanager/FieldsTable')

  const chosenColumns = useMemo(
    () => (!!columns ? columns : DEFAULT_PAGINATED_FIELDS_COLUMNS(t)),
    []
  )

  const { page, filters, setPage, pageSize, setPageSize } = useContext(PaginatedFieldsContext)

  useEffect(() => {
    setTimeout(() => {
      setPage(0)
    }, 0)
  }, [filters, setPage])

  const [sortModel, setSortModel] = useState<GridSortModel>(
    !!initialSort ? (initialSort as GridSortModel) : []
  )
  const [query, setQuery] = useState<FieldQueryOptions>({
    sort: sortModel as SortModel<FieldQuerySortFields>,
  })

  const requestQuery: FieldQueryOptions = {
    ...baseQuery,
    ...filters,
    ...query,
    sort: [...(baseQuery.sort || []), ...(query.sort || [])],
  }
  const paginatedFields = usePaginatedFields(page, pageSize, requestQuery, {
    refetchOnMount: 'always',
  })
  const allFields = usePaginatedFields(page, 0, undefined, { refetchOnMount: 'always' })
  const rows = paginatedFields.data ? paginatedFields.data.items : []

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const mutatedSortModel = mutateSortModel ? mutateSortModel(newSortModel) : newSortModel
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sort, ...newQuery } = query
    setSortModel(mutatedSortModel)
    setQuery({
      ...newQuery,
      sort: mutatedSortModel as SortModel<FieldQuerySortFields>,
    })
    setPage(0)
  }

  if (paginatedFields.data?.numItems === 0) {
    return (
      <>
        {/* NOTE: this is not ideal, however in some places we handle this as a component 
            and some places it is handled outside of the DataGrid */}
        {slots.toolbar && (
          <Box mx={2} my={1}>
            <slots.toolbar {...slotProps?.toolbar} />
          </Box>
        )}
        <EmptyState
          data-testid="emptyState"
          headerMarkdown={
            !!requestQuery.fullText
              ? t('noResultsHeaderFullTextMd', {
                  fullText: requestQuery.fullText,
                })
              : allFields.data?.numAvailable === 0
              ? t('addFieldsMd')
              : t('noResultsHeaderYourQueryMd')
          }
          helperText={
            requestQuery.fullText
              ? t('noResultsFullTextHelper')
              : allFields.data?.numAvailable === 0
              ? ''
              : t('noResultsQueryHelper')
          }
        />
      </>
    )
  }

  return (
    <DataGridPro
      autoHeight={autoHeight}
      rows={rows}
      columns={chosenColumns}
      disableColumnFilter={true}
      initialState={{
        columns: {
          columnVisibilityModel,
        },
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          ...pinnedColumns,
        },
        sorting: {
          sortModel,
        },
      }}
      loading={dataLoading || paginatedFields.isFetching}
      pagination
      paginationMode="server"
      pageSizeOptions={uniq([...PAGE_SIZES, pageSize])}
      paginationModel={{ page, pageSize }}
      onPaginationModelChange={({ page, pageSize }) => {
        setPage(page)
        setPageSize(pageSize)
      }}
      slotProps={{
        footer: {
          // @ts-ignore this custom prop is not typed in mui's types
          hideFooterSelectedRowCount: false,
        },
        ...slotProps,
      }}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      rowCount={paginatedFields.data?.numAvailable || 0}
      keepNonExistentRowsSelected
      slots={{
        ...slots,
      }}
      {...dataGridProProps}
    />
  )
}
