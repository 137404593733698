import { Config } from '../../types'
import { AgentOptions, getBaseAgent } from './baseAgent'
import { GlobalAuthState } from './GlobalAuthState'

export const getMpNodeAgent = (options?: AgentOptions) =>
  getBaseAgent({
    ...options,
    requestMiddlewares: [
      ...(options?.requestMiddlewares || []),
      config => ({ ...config, baseURL: Config.get('NODE_SERVICE_URL') }),
      // some mp-node services use the mp jwt to fetch user's data
      // see packages/mp-node-service/src/utils/authMiddleware.ts
      GlobalAuthState.requestMiddleware,
    ],
  })
