import { BenchmarkStateTraitReason, BenchmarkStatusReason, getExceededCapLabel, TraitId } from '@cibo/core'
import { useReasonMessages } from '@cibo/ui'
import { Box, Stack, Typography } from '@mui/material'
import { groupBy, groupWith, pluck, prop, uniq } from 'ramda'
import { useTranslation } from 'react-i18next'

export interface BenchmarkStatusReasonsProps {
  fieldId?: string
  reasons: BenchmarkStatusReason[]
  readOnly?: boolean
}

export const BenchmarkStatusReasons = ({
  fieldId,
  reasons,
  readOnly,
}: BenchmarkStatusReasonsProps) => {
  const { t } = useTranslation('traits')
  const groupedTraits = groupBy(prop('traitId'), reasons || [])

  const { byReasonCode } = useReasonMessages()

  return (
    <Stack spacing={1}>
      {Object.keys(groupedTraits).map(traitId => {
        const reasons = groupBy(prop('reason'), groupedTraits[traitId as TraitId])

        return (
          <Box key={traitId}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="default">
              {traitId === 'geometry' && getExceededCapLabel(Object.values(reasons)[0][0])
                ? t('programLimits')
                : t(traitId)}
            </Typography>

            {Object.keys(reasons).map(reason => {
              const yearlyReasons = reasons[reason as BenchmarkStateTraitReason]

              const years = uniq(pluck('year', yearlyReasons)).sort()

              //@ts-ignore
              const yearGroups = groupWith((a, b) => b - a === 1, years)

              //then - note space in join
              const yearString = yearGroups
                .map(group =>
                  group.length > 2 ? `${group.shift()}-${group.pop()}` : group.join(', ')
                )
                .join(', ')

              return (
                <Stack key={reason} direction="row" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    data-testid={`benchmark-status-reason-${
                      !!getExceededCapLabel(yearlyReasons[0]) || !!yearlyReasons[0].reasonText
                        ? 'from-backend'
                        : 'from-translation'
                    }`}
                  >
                    {byReasonCode({
                      reasonCode: reason as BenchmarkStateTraitReason,
                      traitId: traitId as TraitId,
                      count: yearGroups.length,
                      years: yearString,
                      reasonText:
                        getExceededCapLabel(yearlyReasons[0]) || yearlyReasons[0].reasonText,
                    })}
                  </Typography>
                </Stack>
              )
            })}
          </Box>
        )
      })}
    </Stack>
  )
}
