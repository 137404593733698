import { Config } from '../../types'
import { AgentOptions, getBaseAgent } from './baseAgent'
import { GlobalAuthState } from './GlobalAuthState'

export const getMpAgent = (options?: AgentOptions) =>
  getBaseAgent({
    ...options,
    requestMiddlewares: [
      ...(options?.requestMiddlewares || []),
      config => ({ ...config, baseURL: Config.get('MP_BASE_URL') }),
      GlobalAuthState.requestMiddleware,
    ],
  })
