import { DISTURBANCE_TYPES, MONTH_LIST } from '@cibo/core'
import { NumberInputField, SelectField } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, Typography, styled } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import { EventComponentProps } from '../EventDetail'

const CompactCell = styled(TableCell)(() => ({
  paddingLeft: 4,
  paddingRight: 4,
  verticalAlign: 'top',
  '& .MuiFormControl-root': {
    width: '100%',
  },
}))

export const NaturalDisturbanceEventEditor = ({
  year,
  name,
  onPressRemove,
  parentName,
  eventIndex,
  isImmutable,
}: EventComponentProps) => {
  const { t } = useTranslation('@cibo/landmanager/NaturalDisturbance')
  const fieldMeta = useField(name)[1]

  return (
    <>
      <TableRow key={name}>
        <CompactCell>
          <SelectField
            disabled={isImmutable}
            fieldName={`${name}.month`}
            options={MONTH_LIST.map((item, index) => `${index + 1}`)}
            renderOption={value => t(`month_${value}`)}
            fullWidth
          />
        </CompactCell>

        <CompactCell>
          <SelectField
            disabled={isImmutable}
            fieldName={`${name}.disturbance`}
            options={DISTURBANCE_TYPES}
            renderOption={value => t(`disturbance_${value}`)}
            fullWidth
          />
        </CompactCell>

        <CompactCell>
          <NumberInputField
            disabled={isImmutable}
            name={`${name}.lossPer`}
            min={0}
            max={100}
            unit={'%'}
            data-testid={`${name}.lossPer`}
          />
          {!!fieldMeta.error && fieldMeta.error === 'totalPercentageTooHigh' && (
            <Typography variant="caption" color="error">
              {t('totalLossPercentTooHigh')}
            </Typography>
          )}
        </CompactCell>

        <CompactCell>
          <IconButton aria-label={t('removeEvent')} onClick={onPressRemove} disabled={isImmutable}>
            <ClearIcon />
          </IconButton>
        </CompactCell>
      </TableRow>
    </>
  )
}
