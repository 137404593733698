import { TILLAGE_IMPLEMENT, TillageEvent } from '@cibo/core'
import { DatePickerField, NumberInputField, SelectField, TableRow } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import EditRounded from '@mui/icons-material/EditRounded'
import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TableCell,
  TextField,
  Tooltip,
} from '@mui/material'
import { Field, FieldProps, Formik, FormikHelpers, useField } from 'formik'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EventComponentProps } from '../EventDetail'
import { useIsRequired } from '../useIsRequired'
import { TillageEventValidationSchema } from './schema'

export const TillageEventEditor = ({
  editing,
  year,
  name,
  onEditEvent,
  onPressRemove,
  requirement,
  isImmutable,
}: EventComponentProps) => {
  const { t } = useTranslation('@cibo/landmanager/TillageEditor')
  const [{ value }, { error, initialValue, touched }, { setValue, setTouched }] =
    useField<TillageEvent>(name)
  const [localEditing, setLocalEditing] = useState(!initialValue)
  const isRequired = useIsRequired(requirement)

  useEffect(() => {
    onEditEvent(!initialValue)
  }, [!initialValue])

  const defaultMonth = new Date(year, 3)
  const hasError = Object.keys(error || {}).length > 0 && !!initialValue
  const validationSchema = TillageEventValidationSchema({ t, requirement })

  const handleSubmit = (values: TillageEvent, { setSubmitting }: FormikHelpers<TillageEvent>) => {
    setTouched(true, false)
    setValue(values)
    setLocalEditing(false)
    setSubmitting(false)
    onEditEvent(false)
  }

  const editingDisabled = editing || isImmutable

  return (
    <>
      <TableRow hasError={hasError} sx={{ opacity: localEditing ? 0.4 : undefined }}>
        <TableCell sx={{ borderBottomWidth: localEditing ? 0 : 1 }}>
          <Collapse in={!localEditing}>
            {value.date ? t('value', { context: 'date', date: new Date(value.date) }) : '--'}
          </Collapse>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: localEditing ? 0 : 1 }}>
          <Collapse in={!localEditing}>{value.implement ? t(value.implement) : '--'}</Collapse>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: localEditing ? 0 : 1 }}>
          <Collapse in={!localEditing}>
            {value.depth !== undefined
              ? t('value', { context: 'depth', depth: value.depth })
              : '--'}
          </Collapse>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: localEditing ? 0 : 1 }}>
          <Collapse in={!localEditing}>
            {value.residueRemaining !== undefined
              ? t('value', {
                  context: 'residueRemaining',
                  residueRemaining: value.residueRemaining,
                })
              : '--'}
          </Collapse>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: localEditing ? 0 : 1 }}>
          <Collapse in={!localEditing}>
            <Stack direction="row">
              <Tooltip title={t('edit')}>
                <IconButton
                  aria-label={t('editEvent')}
                  onClick={() => {
                    setLocalEditing(true)
                    onEditEvent(true)
                  }}
                  size="small"
                  disabled={editingDisabled}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('remove')}>
                <IconButton
                  aria-label={t('removeEvent')}
                  onClick={onPressRemove}
                  size="small"
                  disabled={editingDisabled}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBlock: 0, borderBottomWidth: localEditing ? 1 : 0 }} colSpan={6}>
          <Formik
            initialValues={value || ({ date: null, implement: '' } as unknown as TillageEvent)}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
            disabled={isImmutable}
            onSubmit={handleSubmit}
          >
            {({ isValid, resetForm, submitForm, values, dirty, errors }) => {
              return (
                <Collapse in={localEditing} timeout="auto" unmountOnExit>
                  <Stack spacing={4} sx={{ paddingBlock: 1 }}>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={2} alignItems="flex-start">
                        <FormControl fullWidth required>
                          <FormLabel>{t('date')}</FormLabel>
                          <DatePickerField
                            name={`date`}
                            minDate={new Date(year - 1, 6, 1)}
                            maxDate={new Date(year + 1, 0, 1)}
                            defaultCalendarMonth={defaultMonth}
                            data-testid={`date`}
                          />
                        </FormControl>

                        <FormControl fullWidth required>
                          <FormLabel>{t('implement')}</FormLabel>
                          <SelectField
                            fieldName="implement"
                            renderOption={t}
                            options={TILLAGE_IMPLEMENT}
                          />
                        </FormControl>
                      </Stack>

                      <Stack direction="row" spacing={2} alignItems="flex-start">
                        <FormControl fullWidth required={isRequired('depth')}>
                          <FormLabel>{t('depth')}</FormLabel>
                          <NumberInputField
                            name={`depth`}
                            data-testid={`depth`}
                            min={0}
                            max={24}
                            unit={t('inch')}
                          />
                        </FormControl>
                        <Field name={`residueRemaining`}>
                          {({ field, form }: FieldProps<number>) => (
                            <FormControl fullWidth required>
                              <FormLabel>{t('residueRemaining')}</FormLabel>
                              <TextField
                                {...field}
                                type="number"
                                onChange={event => {
                                  const newValue = +event.target.value / 100
                                  form.setFieldTouched(`residueRemaining`, true)
                                  form.setFieldValue(`residueRemaining`, newValue)
                                }}
                                error={!!errors.residueRemaining}
                                helperText={errors.residueRemaining}
                                slotProps={{
                                  input: {
                                    inputProps: {
                                      min: 0,
                                      max: 100,
                                      'data-testid': `residueRemaining`,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">{'%'}</InputAdornment>
                                    ),
                                  },
                                }}
                                value={
                                  !!field.value ? numeral(field.value * 100).format('0,0') : ''
                                }
                              />
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                      <Collapse in={values?.implement === 'stripTill'}>
                        <Stack direction="row" spacing={2}>
                          <FormControl fullWidth required>
                            <FormLabel htmlFor={`rowSpacing`}>{t('rowSpacing')}</FormLabel>
                            <NumberInputField
                              name={`rowSpacing`}
                              min={0}
                              max={100}
                              unit={t('inch')}
                              data-testid={`rowSpacing`}
                            />
                          </FormControl>
                          <FormControl fullWidth required>
                            <FormLabel htmlFor={`stripWidth`}>{t('stripWidth')}</FormLabel>
                            <NumberInputField
                              name={`stripWidth`}
                              min={0}
                              max={100}
                              unit={t('inch')}
                              data-testid={`stripWidth`}
                            />
                          </FormControl>
                        </Stack>
                      </Collapse>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        data-testid={`${name}-cancel`}
                        onClick={() => {
                          !initialValue && !touched && onPressRemove()
                          setLocalEditing(false)
                          onEditEvent(false)
                          resetForm()
                        }}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        data-testid={`${name}-add`}
                        size="small"
                        onClick={() => submitForm()}
                        disabled={!isValid || !dirty}
                      >
                        {t('ok')}
                      </Button>
                    </Stack>
                  </Stack>
                </Collapse>
              )
            }}
          </Formik>
        </TableCell>
      </TableRow>
    </>
  )
}
