import { TraitId } from '../ResourceDetails'
import { ResourceDataType } from './BenchmarkConfig'

export type BenchmarkStatusReason = {
  traitId: TraitId
  dataType: ResourceDataType
  year?: number
  hasResult: boolean
  reason: BenchmarkStateTraitReason
  reasonText?: string
  extraData?: {
    // `capInfo` should be removed once the backend is deployed with the switchover to the `exceededCapLabel` field
    capInfo?: string
    exceededCapLabel?: string
  }
}

export function getExceededCapLabel(reason: BenchmarkStatusReason): string | undefined {
  return reason.extraData?.exceededCapLabel || reason.extraData?.capInfo;
}

// see: https://admin.mp.dev.cibo.tech/content/html/endpoint-schema.html?schTypeName=program.PostFieldsQuery#int_program.ReasonCodeChoices
export type BenchmarkStateTraitReason =
  | 'alreadyEnrolledInProgramSharingLedger'
  | 'coverCropFailedToReachReqImprovement'
  | 'coverCropPracticeRegress'
  | 'cropHadCoverCropRegress'
  | 'cropHadTillageRegress'
  | 'fieldPolygonTooSmall'
  | 'fieldSizeNotInValidRange'
  | 'fieldTooLarge'
  | 'fieldTooSmall'
  | 'hasAlreadyHadGoodCoverCropPractice'
  | 'hasAlreadyHadGoodTillagePractice'
  | 'hasHadMoreThanOneDisallowedGoodTillagePractice'
  | 'hasHadMoreThanOneGoodCoverCropPractice'
  | 'incorrectCashCrop'
  | 'ledgerConflict'
  | 'missingData'
  | 'nativeConversionTooLate'
  | 'needChangeOfPractice'
  | 'needsChangeOfBiomassBurning'
  | 'needsChangeOfLivestock'
  | 'needsFertilizer'
  | 'needsFile'
  | 'needsRegenPractices'
  | 'needsSuitableCashCrop'
  | 'noCoverCropImprovement'
  | 'noSuitableCoverCropRegenStartYearFound'
  | 'noSuitableTillageRegenStartYearFound'
  | 'notAvailableForReenrollment'
  | 'noTillageImprovement'
  | 'notInArea'
  | 'tillageFailedToReachReqImprovement'
  | 'tillagePracticeRegress'
  | 'wetlandsDesignationMismatch'
  | 'wouldExceedCaps'
