import { AxiosRequestConfig } from 'axios'
import deepmerge from 'deepmerge'

const axiosConfig: AxiosRequestConfig = {
  headers: {
    'X-MP-AuthType': 'webapp',
  },
  withCredentials: true,
}

type Location = {
  pathname: string
  search: string
  hash: string
}

const authVariables: { isAuthorized?: boolean; mostRecentLocation?: Location } = {
  isAuthorized: false,
  mostRecentLocation: undefined,
}

export const GlobalAuthState = {
  setEnvToken: (envToken: string) => {
    axiosConfig.headers.CbEnvAccessToken = `Bearer ${envToken}`
  },
  clearAuthToken: () => {
    delete axiosConfig.headers.Authorization
  },
  setAuthToken: (authToken?: string) => {
    axiosConfig.headers.Authorization = `Bearer ${authToken}`
  },

  requestMiddleware: (config: AxiosRequestConfig) => deepmerge(config, axiosConfig),

  clearAuthState: (location?: Location) => {
    authVariables.isAuthorized = false
    delete axiosConfig.headers.Authorization
    if (location) authVariables.mostRecentLocation = location
  },
  setAuthStateValid: (authToken?: string) => {
    authVariables.isAuthorized = true
    axiosConfig.headers.Authorization = `Bearer ${authToken}`
  },

  // Auth state for app use
  isAuthorized: () => {
    return authVariables.isAuthorized
  },
  mostRecentLocation: () => {
    return authVariables.mostRecentLocation
  },
  setUnauthorized: () => {
    authVariables.isAuthorized = false
  },
  setAuthorized: () => {
    authVariables.isAuthorized = true
  },
  setMostRecentLocation: (location?: Location) => {
    authVariables.mostRecentLocation = location
  },
}
