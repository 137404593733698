import { getContentfulClient, getLocale, PracticeDetailTypes } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'

const locale = getLocale()

export const usePracticeRecommendationContent = () => {
  return useQuery({
    queryKey: ['practiceRecommendationContent'],
    queryFn: async () => {
      const contentfulClient = getContentfulClient()
      return contentfulClient.getEntries<PracticeDetailTypes>({
        content_type: 'practiceRecommendation',
        locale,
      })
    },
  })
}
