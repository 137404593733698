import { Fade, Popper, PopperProps } from '@mui/material'
import { JSXElementConstructor, ReactNode } from 'react'

interface SearchBarPopperProps extends Pick<PopperProps, 'placement'>, Partial<PopperProps> {
  open: boolean
  anchorEl: any
}

export const SearchBarPopper: JSXElementConstructor<SearchBarPopperProps> = ({
  open,
  anchorEl,
  children,
  placement,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement ? placement : 'bottom-start'}
      transition
      style={{
        pointerEvents: 'none',
        zIndex: 10,
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div
            style={{
              pointerEvents: 'auto',
              width: '650px',
              maxWidth: '95%',
              margin: '15px 0 0',
            }}
          >
            {children as ReactNode}
          </div>
        </Fade>
      )}
    </Popper>
  )
}
