import { AuthAPI, BaseUserOwningOrganization, UserModel, UserOwningOrganization } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Location } from 'react-router-dom'
import {
  authDirectLogout,
  selectAuthSessionResolved,
  selectAuthUserEmail,
  selectAuthUserId,
  selectAuthUserIsLoggedIn,
  selectAuthUsername,
  selectOrgAccountId,
  selectOrgName,
  selectUserPersona,
  selectUserRoles,
} from '../../store'

type Props = {
  onLogout?(): void
}

export const useAuth = (props?: Props) => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectAuthUserIsLoggedIn)
  const authResolved = useSelector(selectAuthSessionResolved)
  const userEmail = useSelector(selectAuthUserEmail)
  const roles = useSelector(selectUserRoles)
  const username = useSelector(selectAuthUsername)
  const persona = useSelector(selectUserPersona)
  const userId = useSelector(selectAuthUserId)
  const orgName = useSelector(selectOrgName)
  const orgId = useSelector(selectOrgAccountId)

  useEffect(() => {
    if (!isLoggedIn) {
      props && props.onLogout && props.onLogout()
    }
  }, [isLoggedIn])

  return {
    authResolved,
    isLoggedIn,
    logout: (location?: Location) => {
      dispatch(authDirectLogout({ location }))
    },
    persona,
    roles,
    userEmail,
    userId,
    username,
    orgName,
    orgId,
  }
}

export const useChildOrgList = () => {
  const { userId } = useAuth()

  return useQuery({
    queryKey: ['organization-children-list', userId],
    queryFn: AuthAPI.config,
    select: ({ data }) =>
      data.userOwningOrganization?.children as Array<BaseUserOwningOrganization> | undefined,
    retry: false,
  })
}

export const useOwningOrg = () => {
  const { userId } = useAuth()

  return useQuery({
    queryKey: ['organization-list', userId],
    queryFn: AuthAPI.config,
    select: ({ data }) => data.owningOrg as UserOwningOrganization,
    retry: false,
  })
}

export const useUserAuthOrg = () => {
  const { userId } = useAuth()

  return useQuery({
    queryKey: ['user-organization', userId],
    queryFn: AuthAPI.config,
    select: ({ data }) => data,
    retry: false,
  })
}

export const useUser = () => {
  const { userId } = useAuth()
  return useQuery({
    queryKey: ['userInfo', userId],
    queryFn: AuthAPI.userInfo,
    select: ({ data }) => new UserModel(data),
    retry: false,
  })
}
