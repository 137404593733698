// See https://admin.mp.dev.cibo.tech/content/md/ProgramFdrStatuses.md for descriptive information
export enum FDRStatus {
  agreementComplete = 'agreementComplete',
  agreementPending = 'agreementPending',
  eligibleForEnrollment = 'eligibleForEnrollment',
  availableForEnrollment = 'availableForEnrollment',
  canEngage = 'canEngage',
  completed = 'completed',
  contracted = 'contracted',
  enrolled = 'enrolled',
  enrolling = 'enrolling',
  futureEnrollment = 'futureEnrollment',
  futureProgram = 'futureProgram',
  hasOffer = 'hasOffer',
  hasOpportunity = 'hasOpportunity',
  hasReward = 'hasReward',
  historicalProgram = 'historicalProgram',
  ineligibleForEnrollment = 'ineligibleForEnrollment',
  isEngaged = 'isEngaged',
  needsInfo = 'needsInfo',
  overlapsField = 'overlapsField',
  overlapsCommitted = 'overlapsCommitted',
  overlapsSameOwner = 'overlapsSameOwner',
  overlapsSelf = 'overlapsSelf',
  profileIncomplete = 'profileIncomplete',
  readyToEnroll = 'readyToEnroll',
  remoteSensingResolved = 'remoteSensingResolved',
  rewardsResolved = 'rewardsResolved',
  ruleIneligible = 'ruleIneligible',
  underReview = 'underReview',
  historicalEnrollment = 'historicalEnrollment',
  reEnrolling = 'reEnrolling',
  confirmationsAvailable = 'confirmationsAvailable',

  // not being accepted by backend
  // postEnrollNoActionNeeded = 'postEnrollNoActionNeeded',
  // isEnrolled = 'isEnrolled',
}

export const OpportunityFDR = [
  FDRStatus.availableForEnrollment,
  FDRStatus.hasOpportunity,
  FDRStatus.eligibleForEnrollment,
  FDRStatus.readyToEnroll,
  FDRStatus.futureEnrollment,
]

export const EnrollingInProgressFDR = [
  FDRStatus.enrolling,
  FDRStatus.reEnrolling,
  FDRStatus.isEngaged,
  FDRStatus.confirmationsAvailable,
]

export const EnrollingActiveFDR = [FDRStatus.hasOffer, FDRStatus.underReview]

export const EnrollingCompletedFDR = [FDRStatus.contracted, FDRStatus.enrolled]

export const EnrolledFDR = [
  ...EnrollingInProgressFDR,
  ...EnrollingActiveFDR,
  ...EnrollingCompletedFDR,
]

export type FDRStatsQueryItem = {
  status: FDRStatus
  programId?: string
  sort?: {
    sortDir: 'asc' | 'desc'
    // path to the value used to sort response items.
    // eg. ["acres"], ["fields"], ["rewards", "opportunities", "usd", "amount"]
    // ["rewards", "opportunities", "carbonCredit", "amount"]
    value: ['fields']
  }
}

export interface FDRStatusEntry {
  status: FDRStatus[]
  programId: string
}

export interface FDRStatusQuery {
  status: FDRStatus
  programId?: string
  isNegated?: boolean
}

export type FieldAction = 'canRemove'
