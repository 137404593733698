import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  Stack,
  ThemeProvider,
} from '@mui/material'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { AppVersion, PolyBackground, THEME_DARK } from '@cibo/ui'
import { BrandLogo } from '../components/BrandLogo'

import { ContactSupportButton } from '../components/ContactSupportButton'
import { useSelfOrgContent } from '../queries'
import { selectIsEnterprise, selectOrgColor } from '../store/index'

export const AuthLayout = ({
  variant = 'form',
  children,
}: PropsWithChildren<{ variant?: 'form' | 'content' }>) => {
  const isEnterprise = useSelector(selectIsEnterprise)
  const orgColor = useSelector(selectOrgColor)
  const { isPending, isFetching } = useSelfOrgContent() // TODO: handle error
  const { t } = useTranslation('auth')

  const background =
    isEnterprise && orgColor
      ? orgColor
      : 'radial-gradient(rgba(32,123,146,255), rgba(16,114,135, 0))'

  return (
    <PolyBackground animated trackMouse>
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        sx={{
          minHeight: '100vh',
          background,
          padding: 2,
        }}
      >
        <Grid size={{ xs: 12, sm: 8, md: 8, lg: 5, xl: 5 }}>
          <Stack
            direction="column"
            spacing={3}
            maxWidth={variant === 'content' ? 800 : 400}
            style={{ margin: '0 auto' }}
          >
            {variant === 'form' && (
              <Stack spacing={4} justifyContent="center" flexDirection="row">
                {isPending || isFetching ? (
                  <Skeleton height={60} width={150} />
                ) : (
                  <ThemeProvider theme={THEME_DARK}>
                    <BrandLogo size="large" logoStyle={{ height: 60, width: 'unset' }} />
                  </ThemeProvider>
                )}
              </Stack>
            )}
            <Card>
              <CardContent style={{ padding: variant === 'form' ? 2 : 0 }}>
                <Box p={variant === 'form' ? 4 : 0} pb={2}>
                  <Outlet />
                  {children}
                </Box>
              </CardContent>

              {variant === 'form' && (
                <CardActions sx={{ paddingInline: 4, paddingBottom: 2 }}>
                  <ContactSupportButton variant="text" buttonText={t('contactUs')} />
                </CardActions>
              )}
            </Card>
            <ThemeProvider theme={THEME_DARK}>
              <AppVersion
                variant="caption"
                sx={{ opacity: 0.4, ':hover,:focus': { opacity: 1 }, transition: 'all 150ms' }}
              />
            </ThemeProvider>
          </Stack>
        </Grid>
      </Grid>
    </PolyBackground>
  )
}
