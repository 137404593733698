import { AuthUserPermission } from '@cibo/core'
import { FriendlyError } from '@cibo/ui'
import { Box, Button, DialogActions, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { Can } from '../Can'

export const FailedToLoadContent = () => {
  const { t } = useTranslation('@cibo/profile/components/FailedToLoadContent')
  const { logout } = useAuth()
  const location = useLocation()

  return (
    <Stack spacing={2}>
      <Box p={2}>
        <FriendlyError message={t('error')} />
      </Box>
      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ width: '100%' }}>
          <Can useAny={AuthUserPermission.DEBUG_USER}>
            <Button variant="outlined" onClick={() => logout(location)}>
              logout
            </Button>
          </Can>
          <Button variant="contained" onClick={() => window.location.reload()}>
            {t('retry')}
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  )
}
